<template>
      <v-app class>
        <div>
          <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
    </v-alert>
        </div>
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <v-container>
              <div class="mb-8 mt-8 text-center">
                <h1>List of  Salary Components</h1>
              </div>
             <v-data-table loading-text="Loading data... Please wait"
                      :loading="!salaryComponents.length"
              :headers= 'headers'
              :items="salaryComponents"
             >
                <template v-slot:item.actions="{ item }" >
                    <div class="d-flex">
                      <v-icon
                        small
                        class="mr-2"
                        @click="edit(item)"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        small
                        @click="openDeleteModal(item)"
                      >
                        mdi-delete
                      </v-icon>

                    </div>
              </template>

             </v-data-table>

              <!-- Dialog for edit  -->
              <Dialog ref="editModal" :title="'Edit'" :width="600">
                <v-row>
                  <TextField v-model="formData.name" :label="'Document Name'"/>
                </v-row>
                <template v-slot:footer>
                  <Button :btnType="'Submit'" :label="'Update'" @onClick="update" class="mr-2"/>
                  <Button :btnType="'Cancel'" :label="'Cancel'" @onClick="cancel('editModal')" />
                </template>
              </Dialog>

              <!-- confirm delete modal  -->
              <Dialog ref="deleteModal" :title="'Are you sure you want to delete'" :width="400">
                <template v-slot:footer>
                  <Button :btnType="'Submit'" :label="'Yes'" @onClick="deleteJobtitle" class="mr-2"/>
                  <Button :btnType="'Cancel'" :label="'No'" @onClick="cancel('deleteModal')" />
                </template>
              </Dialog>
            </v-container>
          </div>
        </div>
      </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'

import Dialog from '@/components/ui/Dialog.vue'
import { UPDATE_SALARY, FETCH_SALARY, DELETE_SALARY } from '@/store/action-type'
export default {
  components: {
    TextField,
    Button,
    Dialog

  },
  data () {
    return {
      search: '',
      formData: {
        name: '',
        id: ''
      },
      valid: false,
      isSavingUser: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      headers: [
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Is TimeSheetDriven',
          value: 'isTimeSheetDriven'
        },
        {
          text: 'Is Allowance',
          value: 'isAllowance'
        },
        {
          text: 'Is Taxable',
          value: 'isTaxable'
        },
        {
          text: 'Is Flat',
          value: 'isFlat'
        },
        {
          text: 'Is Basic',
          value: 'isBasic'
        },
        {
          text: 'Is Based on Basic',
          value: 'isBasedOnBasic'
        },
        {
          text: 'Is Statutory',
          value: 'isStatutory'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    salaryComponents () {
      return this.$store.getters.salaryComponents
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => { this.alert = false }, 5000)
    },
    edit (item) {
      this.$refs.editModal.toggleModal()
      this.formData.name = item.name
      this.formData.id = item.id
    },
    openDeleteModal (item) {
      this.$refs.deleteModal.toggleModal()
      this.formData.name = item.name
      this.formData.id = item.id
    },
    deleteJobtitle () {
      this.$store.dispatch(DELETE_SALARY, this.formData).then((result) => {
        this.showAlertMessage('Jobtitle deleted successfully', 'success')
        // TODO remove this console.log
      }).catch(error => {
        console.log(error.message)
        // TODO please put an alert component here
      })
    },
    update () {
      this.cancel('editModal')
      this.$store.dispatch(UPDATE_SALARY, this.formData).then((result) => {
        this.showAlertMessage('Jobtitle Updated successfully await approval', 'success')
        this.resetForm()
      }).catch(error => {
        console.log(error.message)

        this.showAlertMessage(error.message, 'error')
      })
    },
    resetForm () {
      this.formData.name = ''
      this.formData.id = ''
    },
    cancel (modal) {
      if (modal === 'editModal') {
        this.$refs.editModal.toggleModal()
      }
      if (modal === 'deleteModal') {
        this.$refs.deleteModal.toggleModal()
      }
    }

  },
  mounted () {
    this.$store
      .dispatch(FETCH_SALARY)
      .then(() => {})
      .catch((error) => {
        console.log(error)
        // if (error) {
        //   this.showAlertMessage('Unable to display departments', 'error')
        // }
      })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
